<template>
  <div>
    <v-card class="d-flex justify-end mb-6" flat tile>
      <v-breadcrumbs :items="breadcrumbs" large>
        <template v-slot:divider>
          <v-icon>{{ icons.mdiSlashForward }} </v-icon>
        </template>
      </v-breadcrumbs>
    </v-card>
    <v-card flat class="pa-3 mt-2">
      <v-card-text>
        <v-card-title class="text-no-wrap pt-1 ps-2"> CREATE ROLES</v-card-title>
        <form @submit.prevent="CreateData" ref="form">
          <v-row>
            <v-col cols="12" v-for="error in errors" :key="error">
              <v-alert color="error" text class="mb-0">
                <div class="d-flex align-start">
                  <v-icon color="warning">
                    <!-- {{ icons.mdiAlertOutline }} -->
                  </v-icon>

                  <div class="ms-3">
                    <p class="text-base font-weight-medium mb-1">{{ error }}</p>
                    <!-- <a href="javascript:void(0)" class="text-decoration-none warning--text">
                    <span class="text-sm">Resend Confirmation</span>
                  </a> -->
                  </div>
                </div>
              </v-alert>
            </v-col>

            <v-col md="6" cols="12">
              <v-text-field label="Nama Role" v-model="role.nama_role" dense outlined></v-text-field>
            </v-col>

            <v-col md="6" cols="12">
              <v-text-field label="Deskripsi" v-model="role.deskripsi" dense outlined></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-btn type="submit" color="primary" class="me-3 mt-4"> Save </v-btn>

              <router-link :to="{ name: 'roles-index' }">
                <v-btn color="secondary" outlined class="mt-4" type="reset"> Cancel </v-btn>
              </router-link>
            </v-col>
          </v-row>
        </form>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import {
  mdiAccountOutline,
  mdiCurrencyUsd,
  mdiTrendingUp,
  mdiDotsVertical,
  mdiLabelOutline,
  mdiDelete,
  mdiGreasePencil,
  mdiContentSave,
  mdiSlashForward,
} from '@mdi/js'

export default {
  data() {
    return {
      breadcrumbs: [
        {
          text: 'Dashboard',
          disabled: false,
          href: '/admin/dashboard',
        },
        {
          text: 'Roles',
          disabled: false,
          href: '/admin/roles',
        },
        {
          text: 'Create Role',
          disabled: true,
        },
      ],

      // icons
      icons: {
        mdiGreasePencil,
        mdiDelete,
        mdiContentSave,
        mdiDotsVertical,
        mdiTrendingUp,
        mdiAccountOutline,
        mdiLabelOutline,
        mdiCurrencyUsd,
        mdiSlashForward,
      },

      role: {
        nama_role: '',
        deskripsi: '',
      },
      errors: [],
    }
  },
  mounted() {
    this.$isLoading(true)
    setTimeout(() => {
      this.$isLoading(false)
    }, 2000)
  },
  methods: {
    CreateData(e) {
      console.log('NAMA ROLE : ' + this.role.nama_role)
      if (this.$data.role.nama_role.length === 0) {
        this.errors.push('Nama Role harus diisi !')
        this.$swal.fire({
          title: 'Error',
          text: 'Nama rple wajib diisi !',
          icon: 'error',
          showCancelButton: false,
          showConfirmButton: false,
          timer: 1000,
        })
      } else if (this.$data.role.deskripsi.length === 0) {
        this.errors.push('Deskripsi harus diisi !')

        this.$swal.fire({
          title: 'Error',
          text: 'Deskripsi wajib diisi !',
          icon: 'error',
          showCancelButton: false,
          showConfirmButton: false,
          timer: 1000,
        })
      } else {
        let uri = process.env.VUE_APP_ROOT_API + '/api/admin/roles/store'

        this.axios
          .post(uri, this.role)
          .then(response => {
            this.$swal.fire({
              title: 'Success',
              text: 'Roles created successfully',
              icon: 'success',
              showCancelButton: false,
              showConfirmButton: false,
              timer: 2000,
            })
          })
          .catch(e => {
            // console.log(e);
            this.$swal.fire({
              title: 'Error!',
              text: 'Roles failed to create!',
              icon: 'error',
              showCancelButton: false,
              showConfirmButton: false,
              timer: 1000,
            })
          })

        e.preventDefault()
        setTimeout(() => {
          this.$router.push({ name: 'roles-index' })
        }, 3000)
      }
    },
  },
}
</script>
